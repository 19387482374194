// swiper arrows

.swiper-pagination-bullet {
  background: var(--primary-clr);
  opacity: 0.3;
}

.swiper-pagination-bullet-active {
  background: var(--primary-clr);
  width: 16px;
  border-radius: 50px;
  opacity: 1;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  position: absolute;
  font-family: "bootstrap-icons";
  font-size: 35px;
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  font-variant: initial;
  line-height: 1;
  color: white;
}

.swiper-button-next {
  right: 25px;
  &::after {
    content: "\F285";
  }
}

.swiper-button-prev {
  left: 25px;
  &::after {
    content: "\F284";
  }
}

.cover-swiper-slider {
  [aria-disabled="false"] {
    &::after {
      background: var(--primary-clr);
      color: white;
    }
  }
  [aria-disabled="true"] {
    opacity: 1;
  }
}

// brands slider
.coverBrandSlider {
  .swiper {
    padding-inline: 4rem;
  }
  .swiper-wrapper {
    align-items: center;
  }
  img {
    width: 218px;
    height: 157px;
    object-fit: scale-down;
    margin: auto;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    color: black;
  }
  .swiper-button-next {
    right: 0px;
    &::after {
      content: "\F285";
    }
  }

  .swiper-button-prev {
    left: 0px;
    &::after {
      content: "\F284";
    }
  }
}
.dark {
  .swiper-button-next::after,
  .swiper-button-prev::after {
    color: white;
  }
}

// testimonial slider
.testimonialSlider {
  margin-top: -7rem;
  .swiper-pagination {
    margin-top: 4rem;
  }
  .swiper-button-next {
    top: 25px;
    right: 25px;
    width: 50px;
    height: 50px;
    border: 2px solid var(--primary-clr);
    &::after {
      content: "\F138";
      color: var(--primary-clr);
      font-size: 26px;
    }
  }

  .swiper-button-prev {
    top: 25px;
    right: 85px;
    left: inherit;
    width: 50px;
    height: 50px;
    border: 2px solid var(--primary-clr);
    &::after {
      content: "\F12F";
      color: var(--primary-clr);
      font-size: 26px;
    }
  }
}

// team slider
.teamSlider {
  .swiper-pagination {
    bottom: -70px;
  }
  .swiper-button-next {
    right: 60px;
  }
  .swiper-button-prev {
    left: 60px;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(var(--primary-rgb-clr), 0.3);
    color: var(--primary-clr);
    border-radius: 10px;
  }
}

@import "../components/_mixin";
:root {
  --input-padding: 15px;
  --input-height: 48px;
}
html {
  scroll-padding-top: var(--scroll-padding, 50px);
}
html,
body {
  font-family: "Roboto", sans-serif !important;
  font-weight: 500;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004) !important;
  font-size: 16px;
  overflow-x: hidden;
}

body {
  min-height: 100vh;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
  outline: none;
  letter-spacing: 0.35px;
}

ul,
ol {
  margin: 0 !important;
  padding: 0 !important;
  list-style: none;
}

a {
  text-decoration: none !important;
  transition: 200ms;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

svg {
  transition: 0.1s;
}

section {
  padding: var(--section-padding);
  position: relative;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

p,
li {
  color: var(--paragraph-clr);
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6,
small,
label {
  color: var(--heading-clr);
}

img {
  width: 100%;
  max-width: 100%;
  display: none;
  &[src] {
    display: block;
  }
}

i {
  line-height: normal;
  display: inherit;
}

// form setting
span {
  font-size: inherit;
}
hr {
  background-color: var(--border-clr);
}

label {
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 13px;
  color: #606060;
}

input:not([type="checkbox"]):not([type="radio"]),
select {
  height: var(--input-height);
}
.input-height-49px {
  input:not([type="checkbox"]):not([type="radio"]),
  select {
    height: 49px;
  }
}
input[type="checkbox"] {
  width: 18px;
  height: 18px;
  border: 1px solid rgba(123, 123, 123, 0.2);
  margin-top: 0;
  margin-right: 10px;
  border-radius: 3px !important;
}
// input::-webkit-datetime-edit {
//   color: transparent;
// }
// input:focus::-webkit-datetime-edit {
//   color: #000;
// }
// input:valid::-webkit-datetime-edit {
//   color: #000;
// }
select {
  cursor: pointer;
  min-width: 191px;
}

// tittle effect
.tilt-effect {
  transform-style: preserve-3d;
  perspective: 1000px;
  &:hover {
    i,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span,
    img,
    svg {
      @include transition(0.5s);
      @include transform(translateZ(30px));
    }
  }
}

.progress {
  background: rgba(var(--primary-rgb-clr), 0.2);
  height: 4px;
  @include border-radius(20px);
  .progress-bar {
    background-color: var(--primary-clr);
    @include border-radius(50px);
  }
}
.vr {
  background-color: #7b7b7b7d;
  opacity: 1;
}
.form-switch {
  margin: 0;
  .form-check-input {
    width: 3em;
    border-radius: 2em !important;
    cursor: pointer;
    &:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    }
  }
}
.form-check-input {
  border-color: #cdcdcd;
  flex-shrink: 0;
  &:checked {
    background-color: var(--primary-clr);
    border-color: var(--primary-clr) !important;
  }
  &:focus {
    border-color: var(--primary-clr);
    box-shadow: none;
  }
}
input[type="radio"].form-check-input {
  width: 19px;
  height: 19px;
  background-color: #cdcdcd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
  border-color: #cdcdcd;
  &:checked {
    background-color: var(--primary-clr);
    border-color: var(--primary-clr) !important;
    box-shadow: 0px 2px 5px 0px #0000002b;
  }
  &:focus {
    border-color: var(--primary-clr);
  }
}

.form-group {
  margin-bottom: 1.1rem;
}

.form-control,
.select-box {
  padding: 10px var(--input-padding);
  background: white;
  box-shadow: none;
  border: 1px solid #f1f1f2;
  font-size: 16px;
  color: #828282;
  border-radius: 6px;
  &::placeholder {
    color: #bababa;
  }

  &:focus {
    color: #71748d;
    background-color: #fff;
    border-color: var(--primary-clr);
    outline: 0;
    box-shadow: none;
    &::placeholder {
      color: var(--primary-clr);
    }
    i {
      color: var(--primary-clr);
    }
  }
}

// end form setting
// table setting
tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: var(--border-clr);
}

// .table-hover>tbody>tr:hover>* {
//     --bs-table-accent-bg: var(--table-hover-clr);
//     color: var(--table-td-after-hover);
// }
.table > :not(:first-child) {
  border-top: none;
}

// end table setting
// ################## START CARD AREA  ######################
.card {
  border: 1px solid var(--border-clr);
}

// ################## END CARD AREA  ######################
.column-spacing {
  [class*="col-"] > div {
    margin: 10px;
  }
}

.content-spacing * + * {
  margin-top: 2rem !important;
}

.collapsing {
  height: 0;
  overflow: hidden;
  @include transition(0.2s);
}

.hb-line {
  width: 100px;
  height: 5px;
  background: var(--primary-clr);
  display: block;
  margin-block: 9px;
  @include border-radius(50px);
}

// title setting

.subtitle {
  position: relative;
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
}

.block-title {
  font-size: 36px;
  font-weight: 700;
}

.entry-title {
  font-size: 22px;
  font-weight: 500;
}

.single-title {
  font-size: 30px;
  font-weight: 600;
}

.section-title-para {
  font-size: 18px;
}

// end title settings
.caret-none,
.custom-select-caret-none .select-selected {
  &::after {
    display: none;
  }
}
.disc-items {
  li {
    list-style: disc;
    margin-left: 1.5rem;
    color: var(--paragraph-clr);
    font-weight: 400;

    &:not(last-child) {
      margin-bottom: 1rem;
    }
  }
}

.list-items-check {
  li {
    position: relative;
    font-size: 17px;
    font-weight: 600;

    i {
      font-size: 20px;
      margin-right: 15px;
      display: inline-block;
    }
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    // &::before {
    //   content: "\F26B";
    //   font-family: "Bootstrap-Icons";
    //   top: 0px;
    //   left: -1.5rem;
    //   position: absolute;
    // }
  }
}
.primary-list-items {
  li {
    color: var(--primary-clr);
  }
}

.decimal-items {
  li {
    list-style: decimal;
    margin-left: 1.5rem;
    color: #363232;
    font-weight: 400;
    font-size: 13px;
    opacity: 0.5;
  }
}

.z-index1 {
  z-index: 1;
}
// check text
.checktext {
  position: relative;
  overflow: hidden;
  input[type="radio"] {
    -webkit-appearance: none;
    border: none !important;
    margin: auto;
    position: absolute;
    left: 0px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    &:checked ~ label {
      // background: rgba(95, 193, 60, 0.24);
      // border: 1px solid rgba(95, 193, 60, 0.14);
      color: var(--primary-clr);
    }
  }
  &:hover {
    label {
      color: var(--primary-clr);
    }
  }
  label {
    color: #7b7b7b;
    width: 100%;
    font-size: 12px;
    display: flex;
    align-items: center;
    margin: 0;
    i {
      font-size: 12px;
    }
  }
}
// updonw animate
.updown-animate {
  animation: updownanimate 2s linear infinite;
  @keyframes updownanimate {
    0% {
      @include transform(translateY(0));
    }
    50% {
      @include transform(translateY(-5px));
    }
    100% {
      @include transform(translateY(0px));
    }
  }
}

// shadow dots animate
//   dark color loop
@for $dark-bg-color from 1 through 9 {
  .dark-bg-clr-0#{$dark-bg-color} {
    background-color: #{"rgba(0,0,0,0.#{$dark-bg-color})"};
  }
}
// white-color-loop
@for $white-bg-color from 1 through 9 {
  .white-bg-clr-0#{$white-bg-color} {
    background-color: #{"rgba(248,248,248,0.#{$white-bg-color})"};
  }
}

//   primary color loop
@for $primary-bg-color from 1 through 9 {
  .primary-bg-clr-0#{$primary-bg-color} {
    background-color: rgba(var(--primary-rgb-clr), #{"0.#{$primary-bg-color}"});
  }
}
@for $primary-bg-color from 1 through 9 {
  .primary-bg-clr-00#{$primary-bg-color} {
    background-color: rgba(
      var(--primary-rgb-clr),
      #{"0.0#{$primary-bg-color}"}
    );
  }
}

//   secondary color loop
@for $secondary-bg-color from 1 through 9 {
  .secondary-bg-clr-0#{$secondary-bg-color} {
    background-color: rgba(
      var(--secondary-rgb-clr),
      #{"0.#{$secondary-bg-color}"}
    );
  }
}

// flex
@for $i from 1 through 10 {
  .flex-#{$i} {
    flex: $i;
  }
}

// border
$borders: (
  "top": #{var(--border-clr)},
  "right": #{var(--border-clr)},
  "bottom": #{var(--border-clr)},
  "left": #{var(--border-clr)},
);
@each $keys, $val in $borders {
  .border-#{$keys} {
    border-#{$keys}: 1px solid $val !important;
  }
}

.border {
  border: 1px solid var(--border-clr) !important;
}
.primary-border {
  border-color: var(--primary-clr) !important;
}
.secondary-border {
  border-color: var(--secondary-clr) !important;
}
@for $i from 1 through 15 {
  .padding-inline-#{$i}vw {
    padding-inline: $i + vw !important;
  }
}

@for $i from 1 through 15 {
  .padding-block-#{$i}vw {
    padding-block: $i + vw !important;
  }
}

/*############################3
      opacity
 #########################3*/

.opacity-1 {
  opacity: 1 !important;
}
.hover-opacity-1 {
  opacity: 1 !important;
}

@for $i from 1 through 9 {
  .opacity-0#{$i} {
    opacity: #{"0.#{$i}"} !important;
  }
}
@for $i from 1 through 9 {
  .hover-opacity-0#{$i} {
    @include transition(0.2s);
    &:hover {
      opacity: #{"0.#{$i}"};
    }
  }
}

/*############################3
      opacity
 #########################3*/

/*############################3
      Start font weight
 #########################3*/

@for $i from 1 through 9 {
  .font-weight-#{$i}00 {
    font-weight: $i#{"00"} !important;
  }
}

/*############################3
      End font weight
 #########################3*/

/*############################3
      Start border-radius
 #########################3*/

@for $i from 0 through 50 {
  .border-radius-#{$i}px {
    border-radius: $i#{"px"} !important;
  }
}

/*############################3
      End border-radius
 #########################3*/

$while-width: 5;
@while $while-width <=100 {
  .width-#{$while-width} {
    width: $while-width#{"%"} !important;
  }
  $while-width: $while-width + 5;
}

$while-height: 10;
@while $while-height <=100 {
  .height-#{$while-height}vh {
    height: $while-height + vh !important;
  }
  $while-height: $while-height + 10;
}

$while-height2: 10;
@while $while-height2 <=400 {
  .height-#{$while-height2}px {
    height: $while-height2 + px !important;
  }
  $while-height2: $while-height2 + 5;
}

@for $i from 10 through 100 {
  .font-#{$i}px {
    font-size: $i + px !important;
  }
}

// sm
@media screen and (min-width: 576px) {
  $while-width: 5;
  @while $while-width <=100 {
    .width-sm-#{$while-width} {
      width: $while-width#{"%"} !important;
    }
    $while-width: $while-width + 5;
  }
  $while-height: 10;
  @while $while-height <=100 {
    .height-sm-#{$while-height}vh {
      height: $while-height + vh !important;
    }
    $while-height: $while-height + 10;
  }
  $while-height2: 10;
  @while $while-height2 <=400 {
    .height-sm-#{$while-height2}px {
      height: $while-height2 + px !important;
    }
    $while-height2: $while-height2 + 5;
  }
  @for $i from 10 through 100 {
    .font-sm-#{$i}px {
      font-size: $i + px !important;
    }
  }
  @for $i from 1 through 15 {
    .padding-inline-sm-#{$i}vw {
      padding-inline: $i + vw !important;
    }
  }
  @for $i from 1 through 15 {
    .padding-block-sm-#{$i}vw {
      padding-block: $i + vw !important;
    }
  }
}

// md
@media screen and (min-width: 768px) {
  $while-width: 5;
  @while $while-width <=100 {
    .width-md-#{$while-width} {
      width: $while-width#{"%"} !important;
    }
    $while-width: $while-width + 5;
  }
  $while-height: 10;
  @while $while-height <=100 {
    .height-md-#{$while-height}vh {
      height: $while-height + vh !important;
    }
    $while-height: $while-height + 10;
  }
  $while-height2: 10;
  @while $while-height2 <=400 {
    .height-md-#{$while-height2}px {
      height: $while-height2 + px !important;
    }
    $while-height2: $while-height2 + 5;
  }
  @for $i from 10 through 100 {
    .font-md-#{$i}px {
      font-size: $i + px !important;
    }
  }
  @for $i from 1 through 15 {
    .padding-inline-md-#{$i}vw {
      padding-inline: $i + vw !important;
    }
  }
  @for $i from 1 through 15 {
    .padding-block-md-#{$i}vw {
      padding-block: $i + vw !important;
    }
  }
}

// lg
@media screen and (min-width: 992px) {
  $while-width: 5;
  @while $while-width <=100 {
    .width-lg-#{$while-width} {
      width: $while-width#{"%"} !important;
    }
    $while-width: $while-width + 5;
  }
  $while-height: 10;
  @while $while-height <=100 {
    .height-lg-#{$while-height}vh {
      height: $while-height + vh !important;
    }
    $while-height: $while-height + 10;
  }
  $while-height2: 10;
  @while $while-height2 <=400 {
    .height-lg-#{$while-height2}px {
      height: $while-height2 + px !important;
    }
    $while-height2: $while-height2 + 5;
  }
  @for $i from 10 through 100 {
    .font-lg-#{$i}px {
      font-size: $i + px !important;
    }
  }
  @for $i from 1 through 15 {
    .padding-inline-lg-#{$i}vw {
      padding-inline: $i + vw !important;
    }
  }
  @for $i from 1 through 15 {
    .padding-block-lg-#{$i}vw {
      padding-block: $i + vw !important;
    }
  }
}

// xl
@media screen and (min-width: 1200px) {
  $while-width: 5;
  @while $while-width <=100 {
    .width-xl-#{$while-width} {
      width: $while-width#{"%"} !important;
    }
    $while-width: $while-width + 5;
  }
  $while-height: 10;
  @while $while-height <=100 {
    .height-xl-#{$while-height}vh {
      height: $while-height + vh !important;
    }
    $while-height: $while-height + 10;
  }
  $while-height2: 10;
  @while $while-height2 <=400 {
    .height-xl-#{$while-height2}px {
      height: $while-height2 + px !important;
    }
    $while-height2: $while-height2 + 5;
  }
  @for $i from 10 through 100 {
    .font-xl-#{$i}px {
      font-size: $i + px !important;
    }
  }
  @for $i from 1 through 15 {
    .padding-inline-xl-#{$i}vw {
      padding-inline: $i + vw !important;
    }
  }
  @for $i from 1 through 15 {
    .padding-block-xl-#{$i}vw {
      padding-block: $i + vw !important;
    }
  }
}

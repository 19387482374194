.bganimate {
  background-image: url(../../images/shap//bg-shap2.png);
  background-repeat: repeat-x;
  background-size: cover;
  animation: chartanimate 100s linear infinite;
  @keyframes chartanimate {
    100% {
      background-position: -1440px 0;
    }
  }
}

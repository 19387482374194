.footer-bg {
  background: url(../../images/footerbg.png) no-repeat center/cover;
  .card {
    margin-top: -9.6rem;
  }
  .footer-links {
    li {
      a {
        font-size: 20px;
        font-weight: 500;
        color: white;
        letter-spacing: 0.3px;
        &:hover {
          color: var(--primary-clr);
        }
      }
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
  .emailsend {
    button {
      height: 48px;
      &:hover {
        background: var(--secondary-clr);
        border-color: var(--secondary-clr);
        color: white;
        opacity: 0.7;
      }
    }
  }
  .sociaMediaLinks {
    // position: absolute;
    // top: 50%;
    // right: calc(100vw - 99vw);
    // transform: translateY(-50%);
    ul {
      li {
        span {
          height: 112px;
          width: 4px;
          background: var(--primary-clr);
          border-radius: 10px;
          display: block;
          margin: auto;
        }
        a {
          width: 46px;
          aspect-ratio: 1;
          background: var(--secondary-clr);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            font-size: 20px;
            color: white;
          }
          &:hover {
            background: var(--primary-clr);
          }
        }
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
.footerFormCard {
  --bs-card-bg: #efebff;
}
.dark {
  .footerFormCard {
    --bs-card-bg: #1e222d;
    .card-header {
      h1 {
        color: black !important;
      }
    }
    .card-body {
      input,
      textarea {
        background: #4d5361 !important;
        border-color: #4d5361 !important;
      }
    }
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "\F280";
  font-family: "bootstrap-icons";
  display: none;
}
.dark {
  .breadcrumb-item + .breadcrumb-item::before {
    color: white;
  }
}

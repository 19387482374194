//####################
// START NAV BAR
// #####################

ul {
  .dropdown-menu.dropdown-menus {
    padding: 11px 20px;
    border: 0;
    text-align: left;
    a {
      margin-left: 0;
      line-height: 24px;
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 500;
      color: black !important;
      padding: 0;
      &:hover {
        background-color: transparent !important;
        color: red;
      }
    }
  }
}

@include toggler;
@include sticky-top;
// sticky top add nav to classess fixed-top,nav-fixed

// end top bar
.cover-navbar {
  background: var(--navbg-clr);
  .navbar-brand {
    img {
      width: 250px;
    }
  }
  .navbar-nav {
    gap: 22px;
    .nav-item {
      .nav-link {
        color: white;
        position: relative;
        text-transform: capitalize;
        font-weight: 500;
        font-size: 18px;

        &[aria-current="page"] {
          color: white;
          font-weight: 700;
          &::before {
            content: "";
            width: 100%;
            height: 4px;
            background: white;
            border-radius: 5px 5px 0px 0px;
            position: absolute;
            bottom: -31px;
            left: 50%;
            @include transform(translateX(-50%));
          }
        }
        &:hover {
          opacity: 0.8;
        }
      }
      .primary-gradient-btn {
        font-size: 17px;
      }
    }
  }
}

.humburger {
  max-width: 50px;
  display: flex !important;
  flex-wrap: wrap;

  box-shadow: none;
  border: none;
  padding: 0;
  span {
    height: 5px;
    width: 5px;
    display: block;
    background: white;
    border-radius: 50%;
    transition: none;
    list-style: none;
    transition: all 0.3s ease;
    margin: 4px 5px;
    &:nth-child(2n) {
      background: var(--secondary-clr);
    }
  }
}

//####################
// END NAV BAR
//####################

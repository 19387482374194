//####################
// START MIXIN
// #####################
@mixin fontsize($fontSize, $fontClr, $fontWeight, $textTransform, $lineHeight) {
  font-size: $fontSize;
  color: $fontClr;
  font-weight: $fontWeight;
  text-transform: $textTransform;
  line-height: $lineHeight;
}

@mixin border-radius($borderRadiusValue) {
  border-radius: $borderRadiusValue;
  -webkit-border-radius: $borderRadiusValue;
  -moz-border-radius: $borderRadiusValue;
  -ms-border-radius: $borderRadiusValue;
  -o-border-radius: $borderRadiusValue;
}

@mixin transition($transition) {
  transition: $transition;
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-transition: $transition;
}

@mixin transform($transfrom) {
  transform: $transfrom;
  -webkit-transform: $transfrom;
  -moz-transform: $transfrom;
  -ms-transform: $transfrom;
  -o-transform: $transfrom;
}

@mixin sticky-top {
  .nav-sticky {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    position: fixed !important;
    background: white;
    right: 0;
    left: 0;
    top: 0;
    z-index: 1041;
    animation-fill-mode: none;
    animation: slideInDown 1s cubic-bezier(0.23, 1, 0.32, 1);
    -webkit-animation: slideInDown 1s cubic-bezier(0.23, 1, 0.32, 1);
  }
}

@mixin toggler {
  .my-toggler {
    border: 0 !important;
    background: transparent;
    span {
      width: 25px;
      height: 2px;
      background-color: #222222;
      display: block;
      &:nth-child(1) {
        margin: 3px auto;
      }
      &:nth-child(2) {
        width: 18px;
        margin-left: auto;
      }
    }
  }
}

@function set-text-color($colorinvert) {
  @if (lightness($colorinvert) >70) {
    @return #333;
  } @else {
    @return #fff;
  }
}

@mixin background-set($colorinvert) {
  background: $colorinvert;
  color: set-text-color($colorinvert);
}

// ###########################
// start image path
// ###########################
$imagepathurl: "assets/images/";
@function imagepath($url) {
  @return url($imagepathurl + $url);
}

// ###########################
// end image path
// ###########################
// ###########################
// start triangle shapes
// ###########################
@mixin triangle($size, $color, $direction) {
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: $size;
  @if $direction==up {
    border-bottom-color: $color;
  } @else if $direction==right {
    border-left-color: $color;
  } @else if $direction==down {
    border-top-color: $color;
  } @else if $direction==left {
    border-right-color: $color;
  } @else {
    @error "Unknown direction #{$direction}.";
  }
}

// ###########################
// end triangle shapes
// ###########################
// ###########################
// start social media icon text
// ###########################
$social-clrs: (
  "facebook": "#3b5999",
  "twitter": "#55acee",
  "instagram": "#e4405f",
  "youtube": "#cd201f",
  "linkedin": "#0077B5",
  "google-plus": "#dd4b39",
  "pinterest": "#bd081c",
  "skype": "#00AFF0",
);
@mixin social-clr($user) {
  @each $key, $val in $social-clrs {
    @if $key==$user {
      .social-#{$key} {
        color: #{$val};
      }
    }
  }
}

@mixin social-bg-clr($user) {
  @each $key, $val in $social-clrs {
    @if $key==$user {
      .social-bg-#{$key} {
        color: #{$val};
      }
    }
  }
}

@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}
//####################
// END MIXIN
// #####################

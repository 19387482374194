//####################
// START VARIABALES
// #####################
$color1: #7f4cff;

:root {
  --primary-clr: #7f4cff;
  --primary-rgb-clr: 127, 76, 255;
  --secondary-clr: #f04c30;
  --secondary-rgb-clr: 240, 76, 48;
  --section-padding: 80px 0;
  --border-clr: #eee;
  --heading-clr: #010f20;
  --black-btn-clr: #010f20;
  --black-clr-1: #010f20;
  --anker-hover-clr: #010f20;
  --bs-accordion-color: var(--black-clr-1);
  --navbg-clr: #7f4cff;
  --cardwhite-clr: white;
  --paragraph-clr: #606060;
  --light-gray-clr: #f1f1f2;
  scroll-behavior: unset;
}
body.dark {
  --primary-clr: #fee78d;
  --primary-rgb-clr: 127, 76, 255;
  --secondary-clr: #f04c30;
  --secondary-rgb-clr: 240, 76, 48;
  --section-padding: 80px 0;
  --border-clr: #eee;
  --heading-clr: white;
  --black-btn-clr: #010f20;
  --black-clr-1: #010f20;
  --anker-hover-clr: #010f20;
  --navbg-clr: #140e28;
  --cardwhite-clr: #1e222d;
  --paragraph-clr: #f1f1f2;
  --light-gray-clr: #1e222d;
  --bs-dark-rgb: 255, 255, 255;
  .modal {
    --bs-modal-bg: #1e222d;
    .btn-close {
      filter: brightness(0) invert(1);
      opacity: 1;
    }
  }

  scroll-behavior: unset;
  background: #140e28;
  .primary-btn {
    color: black;
  }
  .accordion {
    --bs-accordion-color: white;
    --bs-accordion-active-color: var(--primary-clr);
  }
  .offcanvas {
    --bs-offcanvas-bg: black;
  }

  .according-style-1 .accordion-item .collapse,
  .according-style-1
    .accordion-item
    .accordion-header
    button[aria-expanded="true"] {
    background: rgba(254, 231, 141, 0.5);
  }
  .accordion-body {
    p {
      color: #fee78d !important;
    }
  }
  .cover-navbar {
    .nav-link {
      &.active {
        color: var(--primary-clr) !important;
      }
      &[aria-current="page"]::before {
        background: var(--primary-clr) !important;
      }
    }
  }
}
.primary-bg {
  background: var(--primary-clr) !important;
}

.primary-text {
  color: var(--primary-clr) !important;
}

.primary-anchor {
  color: var(--primary-clr) !important;
  &:hover {
    color: var(--anker-hover-clr) !important;
  }
}
.light-gray-bg {
  background: var(--light-gray-clr);
}
.white-anchor {
  color: white !important;
  &:hover {
    color: var(--primary-clr) !important;
  }
}
.secondary-anchor {
  color: var(--secondary-clr) !important;
  &:hover {
    color: var(--primary-clr) !important;
  }
}

.secondary-bg {
  background: var(--secondary-clr) !important;
}

.secondary-text {
  color: var(--secondary-clr) !important;
}

//####################
// END VARIABALES
// #####################
//####################
// START IMPORT FILES
// #####################

@import "assets/sass/_all_components";
//####################
// END IMPORT FILES
// #####################

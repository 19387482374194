.policy-content {
  p {
    line-height: 30px;
    color: #333333;
  }
  h5 {
    font-weight: 700;
    font-size: 20px;
  }
}

.WeDifferentCard {
  box-shadow: 0px 0px 8px 0px #1f1f1f40;
  border-radius: 12px;
  padding: 2.5rem;
  height: 100%;
  img {
    height: 194px;
    object-fit: scale-down;
  }
  p {
    font-size: 20px;
    font-weight: 400;
  }
}
.dark {
  .WeDifferentCard {
    --bs-card-bg: #1e222d;
  }
}

// foucs card
.howworksection {
  margin-top: -15rem;
}
.focusCard {
  --bs-card-spacer-y: 2rem;
  --bs-card-spacer-x: 2rem;
  --bs-card-box-shadow: 0px 0px 8px 0px #1f1f1f40;
  --bs-card-border-radius: 12px;

  box-shadow: var(--bs-card-box-shadow);
  height: 100%;
  border: none !important;
  transition: 200ms;
  .count {
    width: 100px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: var(--bs-card-bg);
    margin-top: -2.8rem;
    h2 {
      width: calc(100px - 20px);
      aspect-ratio: 1;
      border-radius: 50%;
      color: var(--primary-clr);
      border: 1px solid var(--primary-clr);
    }
  }
  &:hover {
    box-shadow: none;
    transform: translateY(-5px);
  }
}
.dark {
  .focusCard {
    --bs-card-bg: #4d5361;
  }
}

.teamCard {
  height: 100%;
}

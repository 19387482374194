.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 144px;
  height: 34px;
  background: transparent;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
  border: 1px solid #f1f1f2;
  // overflow: hidden;
  margin: 0;
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: -1px;
  left: 0px;
  width: calc(144px / 2);
  height: 34px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 0px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 60px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1230px;
  }
}
@media (min-width: 1440px) {
  .container {
    max-width: 1250px;
  }
}
@media screen and (max-width: 992px) {
  /* logo */
  .cover-navbar .navbar-nav .nav-item .nav-link.active::before {
    display: none;
  }
  .footer-bg .card {
    margin-top: 0rem;
  }
  .footer-bg img[alt="footer logo"] {
    margin: auto;
  }
  .cover-navbar .navbar-nav .nav-item .nav-link[aria-current="page"] {
    color: var(--primary-clr);
    font-weight: 700;
  }

  /* slider */
  .teamSlider .swiper-pagination {
    bottom: -40px;
  }
  .footer-bg .sociaMediaLinks ul li:not(:last-child) {
    margin-bottom: 0;
  }
  body.dark .cover-navbar .navbar-nav .nav-item .nav-link {
    color: white;
  }
  body.dark .react-switch-label .react-switch-button {
    background: var(--primary-clr);
    color: black;
  }
  body.dark .btn-close {
    color: white;
    opacity: 1;
    filter: brightness(0) invert(1);
  }
  body.dark .react-switch-label > div span {
    color: white !important;
  }
  .cover-navbar .navbar-nav .nav-item .nav-link {
    color: black;
  }
  .react-switch-label .react-switch-button {
    background: var(--primary-clr);
    color: white;
  }
  .react-switch-label {
    border: 1px solid var(--primary-clr);
    margin: 0;
  }
  .react-switch-label > div span {
    color: black !important;
  }
}
@media screen and (max-width: 786px) {
  /* team slider */
  .teamSlider {
    margin-top: 2rem;
  }
  .teamSlider .swiper-button-next::after,
  .teamSlider .swiper-button-prev::after {
    width: 50px;
    height: 50px;
    font-size: 25px;
  }
  .teamSlider .swiper-button-next,
  .teamSlider .swiper-button-prev {
    top: -40px;
  }
  .teamSlider .swiper-button-next {
    right: 14px;
  }
  .teamSlider .swiper-button-prev {
    right: 70px;
    left: inherit;
  }
  .teamCard img {
    height: 450px !important;
    object-fit: contain !important;
  }
}
@media screen and (max-width: 576px) {
  /* global */
  section {
    padding: 60px 0;
  }

  .subtitle {
    font-size: 19px;
  }

  .cover-navbar .navbar-brand img {
    width: 180px;
  }
  .technologycard {
    padding: 1.5em;
  }
  /* testimonial */
  .testimonialSlider {
    margin-top: -1rem;
  }
  .mainslider {
    --equalHeight: 27vh;
  }
  .mainslider .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 20px;
  }
  /* team card */
  .teamCard img {
    height: 450px !important;
    object-fit: cover !important;
  }

  /* faq */
  .according-style-1 .accordion-item .accordion-header button {
    font-size: 18px;
  }
}

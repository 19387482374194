//####################
// Start according section
// #####################
.according-style-1 {
  --bs-accordion-active-color: var(--bs-accordion-color);
  .accordion-item {
    border: 0;
    // margin-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
    background-color: transparent;

    .accordion-header {
      padding: 0;
      border: 0;
      button {
        background-color: transparent;
        position: relative;
        color: var(--bs-accordion-color);
        font-weight: normal;
        // padding: 20px 40px 20px 0px;
        padding: 20px 50px 20px 20px;
        display: block;
        line-height: normal;
        font-weight: 500;
        font-size: 20px;

        &[aria-expanded="true"] {
          background: #f1f1f2;
          color: var(--bs-accordion-active-color);
        }
        &::after {
          content: "\F2EA";
          font-family: "Bootstrap-Icons";
          font-size: 28px;
          font-weight: normal;
          position: absolute;
          right: 30px;
          top: 50%;
          text-align: center;
          line-height: 1.25rem;
          background-image: none;
          @include transform(translateY(-50%));
        }
        &[aria-expanded="false"]::after {
          content: "\F64D";
        }
        &:not(.collapsed) {
          box-shadow: none;
        }
      }
      &:hover {
        button {
          color: var(--primary-clr);
        }
      }
    }
    .accordion-body {
      // border: 1px solid #f3f3f3;
      border-top: 0;
      max-height: 200px;
      overflow-y: auto;
      p {
        margin-bottom: 0;
        line-height: 32px;
        font-size: 16px;
        color: #444444;
        font-weight: 500;
      }
    }
    .collapse {
      background: #f1f1f2;
      transition: background 0.15s;
    }
  }
}

%btn {
  padding: 7px 20px;
  background: var(--primary-clr);
  color: white;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  @include border-radius(4px);
  border: 1px solid var(--primary-clr);
  white-space: nowrap;
  font-size: 20px;
  font-weight: 600;
  i {
    font-size: 20px;
  }
  svg {
    font-size: 20px;
  }
  img {
    width: 23px;
  }
  &:hover {
    color: white;
    border: 1px solid var(--black-btn-clr);
    background: var(--black-btn-clr);
    svg {
      fill: var(--primary-clr);
    }
    img {
      filter: var(--primary-filter);
    }
  }
}

.right-icon {
  i {
    margin-right: -7px;
    // margin-left: 7px;
  }
}

.left-icon {
  i {
    margin-left: -7px;
    // margin-right: 7px;
  }
}

button {
  display: inline-flex !important;
  align-items: center !important;
  cursor: pointer;
  @include transition(0.1s !important);
}

.primary-btn {
  @extend %btn;
  &:hover {
    background: transparent;
    color: var(--primary-clr);
    border: 1px solid var(--primary-clr);
  }
  svg {
    fill: white;
  }
}
.primary-gradient-btn {
  @extend %btn;

  background: linear-gradient(to bottom, #dd4276 0%, #fc5c66 100%);
  background-size: 100% 100%;
  border: 1px solid transparent;
  // background: transparent;
  &:hover {
    //background-position: 100% 0;
    background: linear-gradient(to bottom, #dd4276 0%, #fc5c66 -100%);
    border: 1px solid transparent;
  }
}
.primary-gradient-outline-btn {
  padding: 10px 20px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  @include border-radius(8px);
  white-space: nowrap;
  font-size: 18px;
  font-weight: 600;
  border: solid 2px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(180deg, #dd4276 0%, #fc5c66 100%);

  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  color: var(--primary-clr);
  &:hover {
    box-shadow: none;
    color: white;
  }
}
.shadow-btn {
  box-shadow: 0px 4px 8px 0px #f4384359;
  &:hover {
    box-shadow: none;
  }
}
.primary-outline-btn {
  @extend %btn;
  background: transparent;
  border: 2px solid var(--primary-clr);
  color: var(--primary-clr);
  &:hover {
    background: var(--primary-clr);
    border: 1px solid var(--primary-clr);
  }
}

.secondary-btn {
  @extend %btn;
  background: var(--secondary-clr);
  border: 1px solid var(--secondary-clr);
  color: white;
  &:hover {
    background: transparent;
    border: 1px solid var(--secondary-clr);
    color: var(--secondary-clr);
  }
}

.secondary-outline-btn {
  @extend %btn;
  background: transparent;
  border: 1px solid var(--secondary-clr);
  color: var(--secondary-clr);
  &:hover {
    background: var(--secondary-clr);
    border: 1px solid var(--secondary-clr);
  }
}

.black-btn {
  @extend %btn;
  border: 1px solid var(--black-btn-clr);
  background: var(--black-btn-clr);
  &:hover {
    border: 1px solid var(--primary-clr);
    background: var(--primary-clr);
  }
}

.black-outline-btn {
  @extend %btn;
  background: transparent;
  color: var(--black-btn-clr);
  border: 1px solid var(--black-btn-clr);
  &:hover {
    color: white;
    background: var(--black-btn-clr);
  }
}

.white-btn {
  @extend %btn;
  background: white;
  border: 1px solid white;
  color: var(--primary-clr);

  &:hover {
    background: transparent;
    border: 1px solid white;
    color: white;
  }
}

.white-outline-btn {
  @extend %btn;
  background: transparent;
  color: white;
  border: 1px solid white;
  &:hover {
    color: white;
    background: var(--primary-clr);
  }
}
.gray-btn {
  @extend %btn;
  background: #f9f9f9;
  border: 1px solid #f9f9f9;
  color: #8d8d8d;
  &:hover {
    background: #f9f9f9;
    color: white;
    border: 1px solid #f9f9f9;
    opacity: 0.8;
  }
}
.gradient-btn {
  padding: 12px 15px;
  color: white;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  white-space: nowrap;
  i {
    font-size: 20px;
  }
  svg {
    font-size: 20px;
  }
  background: linear-gradient(
    90deg,
    var(--secondary-clr) 0%,
    var(--primary-clr) 100%
  );
  border: none !important;
  background-size: 150%;
  background-position: left;
  transition: background-position 1s;
  &:hover {
    color: white;
    background-position: right;
  }
}

.checkbox-btn {
  position: relative;
  overflow: hidden;
  input[type="radio"] {
    -webkit-appearance: none;
    border: none !important;
    margin: auto;
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
    left: 0px;
    width: 100%;
    height: 100%;
    &:checked ~ label {
      background: var(--black-btn-clr);
      border: 1px solid var(--black-btn-clr);
      color: white;
    }
  }
  label {
    cursor: pointer;
    color: #4f4f4f;
    font-weight: 500;
    padding: 10px 15px;
    border: 1px solid #d4d2d2;
    @include border-radius(5px);
    @include transition(0.2s);
    text-align: center;
    width: 100%;
    font-size: 14px;
    &:hover {
      background: var(--black-clr-1);
      color: white;
    }
  }
}

// extra btn padding
$extra-padding: 15;
@while $extra-padding <=80 {
  .extra-btn-padding-#{$extra-padding} {
    padding: 7px #{$extra-padding}px;
  }
  $extra-padding: $extra-padding + 5;
}
